function modalApproveRejectConversion(action) {
  return (e) => {
    // Some modal content is dynamic
    const withdrawalAmount = e.target.dataset.withdrawalAmount
    const depositAmount = e.target.dataset.depositAmount
    const depositCurrency = e.target.dataset.depositCurrency
    $(`#${action}-withdrawal-amount`).text(withdrawalAmount)
    $(`#${action}-deposit-amount`).text(depositAmount)
    $(`.${action}-deposit-currency`).text(depositCurrency)
    $(`#${action}-modal`).modal({
      onApprove: function() {
        const conversionId = e.target.dataset.conversionId
        // Insert the note from the modal into the form
        // Is there a better way to do this? ¯\_(ツ)_/¯
        if ($(`#${action}-modal #final-deposit-amount`).length){
          $(`form#${action}-${conversionId} input.final-deposit-amount`).val($(`#${action}-modal #final-deposit-amount`).val())
        }
        
        if ($(`#${action}-modal .otp-attempt`).length){
          $(`form#${action}-${conversionId} input.otp-attempt`).val($(`#${action}-modal .otp-attempt`).val())
        }

        $(`form#${action}-${conversionId} input.note`).val($(`#${action}-modal .note`).val())

        $(`form#${action}-${conversionId}`).trigger('submit')
      }
    })
    .modal('show')
  }
}

$(document).on('turbolinks:load', () => {
  $('.conversion-approve-button').on('click', modalApproveRejectConversion("approve"));
  $('.conversion-reject-button').on('click', modalApproveRejectConversion("reject"));
});