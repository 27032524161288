semantic = {};

semantic.ready = () => {
  // Initialization
  $(".ui.accordion").accordion();
  $(".ui.dropdown").dropdown();
  $('.progress').progress();
  $('table.sortable').tablesort();

  // Default users table to wallet balance
  var userTable = $('#users_table');
  if (userTable.length > 0) {
    // We need to do this twice because sortable doesn't set an index and defaults
    // to ascending first for some reason
    userTable.data('tablesort').sort($("th.default-sort").first(), "descending");
    userTable.data('tablesort').sort($("th.default-sort").first(), "descending");
  }

  // Buttons
  const buttonGroup = $(".ui.buttons .button");
  const groupButtonHandler = {
    activate: function () {
      $(this).addClass("active").siblings().removeClass("active");
    },
  };
  buttonGroup.click(groupButtonHandler.activate);
};

$(document).on('turbolinks:load', () => {
  semantic.ready();

  // sidebar slide in/out
  const sidebarNav = $("#sidebar")
  const hamburgerMenu = $("#sidebar-show-btn")
  hamburgerMenu.on('click', (e) => {
    sidebarNav.toggleClass("open")
    hamburgerMenu.toggleClass("open")
  });

  // make flash messages dismissable (e.g. "You must sign in to access this page")
  $('.ui.message i.close.icon').on('click', e => {
    $(e.target).parent().slideUp(300);
  })

  $('.page-changer-dropdown').dropdown({
    onChange: function(value) {
      // If URL has search params, replace them with the values specified by the dropdown.
      // Else, just append new search params to the URL
      // Note: use `window.location.href = ` if using "back" button is desired
      //Input should be the first child of dropdown div and have a name attribute describing the param eg (input type="hidden" name="status")
      let param = this.childNodes[0].name
      if (window.location.href.match(/\?\w+=\w+$/)) {
        window.location.replace(window.location.href.replace(/\w+=\w+$/, `${param}=${value}`))
      } else {
        window.location.replace(`${window.location.href}?${param}=${value}`)
      }
    }
  })

  const addFilterFields = () => {
    const currency = $(".amount-dropdown .text").text();
    $('input[name="currency"]').val(currency);
    const year = $(".year-dropdown .text").text();
    $('input[name="year"]').val(year);
    $('input[name="page"]').val(1);
    $('form#search-form').trigger('submit');
  }

  $('.year-dropdown').dropdown({
    onChange: function(value, text, e){
      addFilterFields();
    }
  })

  $('.amount-dropdown').dropdown({
    onChange: function(value, text, e){
      addFilterFields();
    }
  })

  $('#created_at').on('click', function(e) {
    if ($('#created_at_icon').hasClass("down")){
      $('input[name="sort"]').val("asc");
      $('#created_at_icon').removeClass("down");
      $('#created_at_icon').addClass("up");
    } else {
      $('input[name="sort"]').val("desc");
      $('#created_at_icon').removeClass("up");
      $('#created_at_icon').addClass("down");
    }
    $('form#search-form').trigger('submit');
  });

  $('.currency-dropdown').dropdown({
    onChange: function(value, text, e){
      $('.wallet-balance').each(function(idx, el){
        $(el).addClass("hide");
        if (el.classList[1] === text){
          $(el).removeClass("hide");
        }
      })
    }
  })

  $('#download-note').on('click', function(e) {
    $('form#note-form').trigger('submit');
  });

  $('#confirm-plan-submission').on('click', function(e) {
    let name = $('#title').val()
    $('#modal-amount').text(`${name}`)
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#new-position-plan-form').trigger('submit')
      }
    })
    .modal('show')
  });

  //modal for staking_pool create
  $('#confirm-pool-submission').on('click', function(e) {
    let name = $('#title').val()
    $('#modal-amount').text(`${name}`)
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#new-staking-pool-form').trigger('submit')
      }
    })
    .modal('show')
  });

  //modal for ADA Wallet Address 2FA
  $('#ada-wallet-confirm').on('click', function(e) {
    $('span.ada_address').text($(`input.ada-wallet-address`).val())
    $('#ada-modal').modal({
      onApprove: function() {
        if ($(`#ada-modal .otp-attempt`).length){
          $(`form#ada-address-form input.ada-otp-attempt`).val($(`#ada-modal .otp-attempt`).val())
        }
        $('form#ada-address-form').trigger('submit')
      }
    })
    .modal('show')
  });

  $('form#investor-check-form input[type="checkbox"]').on('click', function(e) {
    if ($(this).attr('checked')) {
      $(this).attr('checked', false)
    } else {
      $(this).attr('checked', true)
    }
  });

  $('#certify-investor-responses').on('click', function(e) {
    const nonAccreditedInvestors = $('form#investor-check-form input[name="non_accredited_investors"]').val();
    let questionsChecked = 0;
    // Determine how many investor questions were selected
    $('form#investor-check-form input[type="checkbox"]').each(function() {
      if ($(this).attr('checked')) {
        questionsChecked ++;
      }
    })
    // Non-accredited investors recieve a warning modal to check their status
    if (questionsChecked == 0 && nonAccreditedInvestors < 35) {
      $('#confirm-status-modal').modal({
        onApprove: function() {
          $('form#investor-check-form input[name="accredited_investor"]').val(false);
          $('form#investor-check-form').trigger('submit');
        }
      })
      .modal('show')
    // If limit of non-accredited investors has been reached, no new non-accredited users can be added.
    } else if (questionsChecked == 0 && nonAccreditedInvestors >= 35) {
      $('#error-modal').modal('show')
    // Accredited users may continue without limitation
    } else {
      $('form#investor-check-form input[name="accredited_investor"]').val(true);
      $('form#investor-check-form').trigger('submit');
    }
  });

  $('#confirm-referrer-submission').on('click', function(e) {
    let name = $('#referrer_name').val()
    let rate = $('#referrer_referral_rate').val()
    $('#modal-name').text(`${name}`)
    $('#modal-rate').text(`${rate}`)
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#referrer-form').trigger('submit')
      }
    })
    .modal('show')
  });

  $('#confirm-referrer-delete').on('click', () => {
    $('#confirm-modal').modal('show')
  });

  //this functions as a delete modal and submit when we want to allow for deletion of individual elements on an index page.
  //allows modal to have one passed in value which can be supplied via resourceName, using semantics modal onApprove functionality.
  $('.confirm-many-delete').on('click', (e) => {
    const resource_id = e.target.dataset.resourceId
    const name = e.target.dataset.resourceName
    debugger
    $('#confirm-many-delete-modal span#resource-name').text(name)
    $(`#confirm-many-delete-modal`).modal({
      onApprove: function() {
        $(`form#delete-${resource_id}`).trigger('submit')
      }
    }).modal('show');
  });

  $('#transaction-export').on('click', () => {
    $('form#export-form').trigger('submit')
  })

  $('input#auto-reinvest-toggle').on('click', function(e) {
    $('form#auto-reinvest-form').trigger('submit')
  })

  $('input#position_auto_reinvest').on('click', function(e) {
    if ($('input#position_auto_reinvest').attr('checked')) {
      $('input#position_auto_reinvest').attr('checked', false)
    } else {
      $('input#position_auto_reinvest').attr('checked', true)
    }
  })

  $('#commission-confirm').on('click', function(e) {
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#edit-commission-form').trigger('submit')
      }
    })
    .modal('show')
  })

  $('#conversion-confirm').on('click', function(e) {
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#new-conversion-form').trigger('submit')
      }
    })
    .modal('show')
  })

  $('#staking-withdraw-all-confirm').on('click', function(e) {
    $('#modal-amount').text('All Funds')
    $('#modal-amount-description').html('<b>All Funds</b> also includes any rewards added when the current epoch ends.')
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#new-resource-form').trigger('submit')
      }
    })
    .modal('show')
  })

  $('#confirm-submission').on('click', function(e) {
    let wholeAmount = $('#amount_whole').val()
    let decimalAmount = $('#amount_decimal').val()
    // would be better to use a real "left pad" for wholeAmount and "right pad" for decimalAmount, but this is must easier
    if (wholeAmount === "") {
      wholeAmount = "0"
    }
    if (decimalAmount === "") {
      decimalAmount = "00"
    }
    const reinvest = $('input#position_auto_reinvest').attr('checked')
    if (reinvest) {
      $('#reinvest-message').html("<b>Note: You have opted to <em>automatically reinvest</em>.  When this position closes, the principal and interest will NOT be available in your deposit wallet; instead, both the principal and interest  will be compounded in a new position.</b>" )
      $('#admin-reinvest-message').html("<b>Note: You have opted to <em>automatically compound</em> for your client.  When this position closes, the principal and interest will NOT be available in your client's deposit wallet; instead, both the principal and interest will be compounded in a new position.</b>" )
    } else {
      $('#reinvest-message').text("")
      $('#admin-reinvest-message').text("")
    }
    const currency = $('#currency-select').text()
    $('#modal-amount').text(`${currency} ${wholeAmount}.${decimalAmount}`)
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#new-resource-form').trigger('submit')
      }
    })
    .modal('show')
  });

  //Admin index pages
  //pins next to users in user column trigger filling of search input and submit the search form.
  $('.trigger-search-pin').on('click', function(e) {
    e.preventDefault()
    const email = $(this).data("userEmail")
    const search = $('input#search_query')
    const searchForm = $('form.index-search')
    if(search.val() == email){
      search.val("")
    }
    else{
      search.val(email)
    }
    searchForm.trigger('submit')
  })

  // Admin add new user page
  $('input#user_business').on('click', function(e) {
    if ($('input#user_business').attr('checked')) {
      $('input#user_business').attr('checked', false);
      $('input#user_business_name').prop('disabled', true);
    } else {
      $('input#user_business').attr('checked', true);
      $('input#user_business_name').prop('disabled', false);
    }
  })

  // Individual Registration - Open Account Page and User - New Account Page
  $('#confirm-account-registration').on('click', () => {
    let name = $('#account-type').val()

    if (name == "Account::SelfDirectedRothIRA" || name == "Account::SelfDirectedIRA"){
      let displayName = name == "Account::SelfDirectedRothIRA" ? "Self Directed Roth IRA" : "Self Directed IRA"
      $('#modal-name').text(`${displayName}`)
      $('#confirm-modal').modal({
        onApprove: function() {
          $('form#new-account-form').trigger('submit')
        }
      })
      .modal('show')
    } else {
      $('form#new-account-form').trigger('submit')
    }
  });

  // Show different tax form fields based on tax classification on new account/tax-form fields
  $('div.tax-form-tax-class').on('click', function(e){
    // Limited Liability Company
    if ($('select#tax-form-tax-class').val() == "Limited Liability Company"){
      $('div.tax-form-name').hide();
      $('input#tax-form-name').val(null);
      $('div.tax-form-business-name').show();
      $('div.tax-form-llc-sub-class').show();
      $('div.tax-form-ein').show();
      $('div.tax-form-ssn').hide();
      $('input#tax-form-ssn').val(null);
    } else if ($('select#tax-form-tax-class').val() == "C Corporation" || $('select#tax-form-tax-class').val() == "S Corporation" || $('select#tax-form-tax-class').val() == "Partnership" || $('select#tax-form-tax-class').val() == "Trust/Estate" ){
      $('div.tax-form-name').hide();
      $('input#tax-form-name').val(null);
      $('div.tax-form-business-name').show();
      $('div.tax-form-llc-sub-class').hide();
      $('select#tax-form-llc-sub-class').val(null);
      $('div.tax-form-ein').show();
      $('div.tax-form-ssn').hide();
      $('input#tax-form-ssn').val(null);
    } else if ($('select#tax-form-tax-class').val() == "Sole Proprietor" || ($('select#tax-form-tax-class').val() == "Single-Member LLC" && $('input#account-business').val() == "true") ){
      $('div.tax-form-name').show();
      $('div.tax-form-business-name').show();
      $('div.tax-form-llc-sub-class').hide();
      $('select#tax-form-llc-sub-class').val(null);
      $('div.tax-form-ein').show();
      $('div.tax-form-ssn').show();
    } else if ($('select#tax-form-tax-class').val() == "Single-Member LLC" && $('input#account-business').val() == "false") {
      $('div.tax-form-name').show();
      $('div.tax-form-business-name').show();
      $('div.tax-form-llc-sub-class').hide();
      $('select#tax-form-llc-sub-class').val(null);
      $('div.tax-form-ein').show();
      $('div.tax-form-ssn').hide();
      $('input#tax-form-ssn').val(null);
    } else if ($('select#tax-form-tax-class').val() == "Individual"){
      $('div.tax-form-name').show();
      $('div.tax-form-business-name').hide();
      $('input#tax-form-business-name').val(null);
      $('div.tax-form-llc-sub-class').hide();
      $('select#tax-form-llc-sub-class').val(null);
      $('div.tax-form-ssn').show();
      $('div.tax-form-ein').hide();
      $('input#tax-form-ein').val(null);
    }
  });

  $('div.admin-tax-form-tax-class').on('click', function(e){
    if ($('select#admin-tax-form-tax-class').val() == "Limited Liability Company"){
      $('div.admin-tax-form-llc-sub-class').show();
    } else {
      $('div.admin-tax-form-llc-sub-class').hide();
      $('select#admin-tax-form-llc-sub-class').val(null);
    }
  })

  //on admins/admins/show make authorize other users updateable
  $('#enable_authorize_admins').on('click', function(e) {
    $('td#authorize_admins').toggleClass('disabled')
    $(this).toggleClass('open green')
  })

  // Associate a new tax form with account
  $('button#associate-new-tax-form-btn').on('click', function(e){
    $('div#associate-new-tax-form').show();
    $('div#associate-existing-tax-form').hide();
    $('select#existing-tax-form-id').val(null);
  });

  // Associate an existing tax form with account
  $('button#associate-existing-tax-form-btn').on('click', function(e){
    $('div#associate-new-tax-form').hide();
    $('div#associate-existing-tax-form').show();
    $('input#tax-form-name').val(null);
    $('input#tax-form-business-name').val(null);
    $('input#tax-form-tax-class').val(null);
    $('input#tax-form-llc-sub-class').val(null);
    $('input#tax-form-ein').val(null);
    $('input#tax-form-ein-confirmation').val(null);
    $('input#tax-form-ssn').val(null);
    $('input#tax-form-ssn-confirmation').val(null);
    $('input#tax-form-confirmed-at').val(null);
  });

  // Reonboard user modal
  $('button#personal-info-check-btn').on('click', function(e) {
    $('#confirm-modal').modal({
      onApprove: function() {
        $('form#personal-info-check-form').trigger('submit')
      }
    })
    .modal('show')
  });

  // Stop Reonboard user modal
  $('button#stop-personal-info-check-btn').on('click', function(e) {
    $('#stop-confirm-modal').modal({
      onApprove: function() {
        $('form#stop-personal-info-check-form').trigger('submit')
      }
    })
    .modal('show')
  });

  // Tally up referrer commissions
  $('.paid_checkbox').on('click', function(e) {
    // find the rows data amount
    var checked = $(this).prop('checked');
    var $table = $(this).parents('table');
    $(this).parents('tr').attr('data-checked', checked);
    var sum = 0.0;
    $table.find('tr').each(function(i, tr) {
      var $tr = $(tr);
      if ($tr.attr('data-checked') === "true") {
        var amount = parseFloat($tr.attr('data-amount'));
        sum += amount;
      }
    });
    var $payout = $table.find('.payout-amount');
    var text = $payout.html();
    var symbol = text.substring(0, 1);
    $payout.html(symbol + sum.toFixed(2));
  });

  $('.user-override-state').on('change', function(e) {
    const action = this.dataset["url"]
    const override_id = this.dataset["override"]
    spinner = $('#spinner'+override_id)
    spinner.addClass("active")
    $.ajax({
      url: action,
      type: "PUT",
      data: {override_id: override_id, state: this.value},
      success: function(response){
        if (response == "updated"){
          setTimeout(function(){
            spinner.removeClass("active")  
          }, 350)
        }
        else{
          spinner.find('.loader').text("try again")
          setTimeout(function(){
            spinner.removeClass("active")
            spinner.find('.loader').text("Updating")
          }, 2000)
        }
      }
    })
  })
  // convert a number eg 100111222.54321 into a comma separated string that stops at the period 100,111,222.54321
  function numberFormatter(num){
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  //here we calculate out the expected rewards, fees and adjusted rewards when staking funds to a pool
  function stakingNextEpochValues(amount_whole, amount_decimal) {
    let amount = amount_whole + amount_decimal
    let reward_rate = parseFloat($('#dynamic-staking-values').data('reward-rate'));
    let fee_rate = parseFloat($('#dynamic-staking-values').data('fee-rate'));
    let reward = amount * reward_rate
    let fee = reward * fee_rate
    let adjusted_reward = reward - fee
    let apy = parseFloat($('#dynamic-staking-values').data('apy'));
    let annual_reward = amount * apy
    //we add thousands commas in to result and add it to the page
    $('.new_transfer .reward-per-epoch').html(numberFormatter(reward))
    $('.new_transfer .reward-per-year').html(numberFormatter(annual_reward))
    $('.new_transfer .fee-per-epoch').html(numberFormatter(fee))
    $('.new_transfer .adjusted-reward-per-epoch').html(numberFormatter(adjusted_reward))
  }

  $('#transfer-amount-input #amount_whole').on('change', function(e) {
    const amount_whole = $(this).val() ? parseFloat($(this).val().replace(/,/g, '')) : 0;
    const amount_decimal = $('#transfer-amount-input #amount_decimal').val() ? parseFloat('0.' + $('#transfer-amount-input #amount_decimal').val()) : 0;
    stakingNextEpochValues(amount_whole, amount_decimal)
  })

  $('#transfer-amount-input #amount_decimal').on('change', function(e) {
    const amount_decimal = $(this).val() ? parseFloat('0.' + $(this).val()) : 0;
    const amount_whole = $('#transfer-amount-input #amount_whole').val() ? parseFloat($('#transfer-amount-input #amount_whole').val().replace(/,/g, '')) : 0;
    stakingNextEpochValues(amount_whole, amount_decimal)
  })

  $('div .transfer-all').on('change', function(e) {
    let check_box = document.getElementById('transfer_all_funds')
    if (check_box.checked){
      $('#amount_whole').val(null);
      $('#amount_decimal').val(null);
      $('.amount-input-wrapper').addClass('disable-element');
      $('#confirm-submission').hide();
      $('#staking-withdraw-all-confirm').show();
    }
    else{
      $('.amount-input-wrapper').removeClass('disable-element');
      $('#staking-withdraw-all-confirm').hide();
      $('#confirm-submission').show();
    }
  })

  //this allows for selecting a table row which triggers an ajax request to passed in route
  $('.staking-pool-row').on('click', function (e) {
    if(!$(this).hasClass('hightlight-row')){
      $(this).addClass('highlight-row').siblings().removeClass('highlight-row');
      const action = this.dataset["url"]
      $.ajax({
        url: action,
        type: "GET"
      });
    }
  });

  $('.staking-pool-row').hover(function() {
    $(this).addClass('highlight-row-light');
  }, function() {
    $(this).removeClass('highlight-row-light');
  });

  $('#calculate-deposit-crypto-amount').on('click', function(e) {
    const withdrawal_currency_to_usd = $('#withdrawal-currency-to-usd').val();
    const deposit_currency_to_usd = $('#deposit-currency-to-usd').val();
    const withdrawal_currency = $('#withdrawal-currency').val();
    let decimal = $('#amount_decimal').val() ? "." + $('#amount_decimal').val() : ""
    const withdrawal_amount = $('#amount_whole').val() + decimal;
    const deposit_amount = (parseFloat(withdrawal_amount.replace(/,/g, '')) * withdrawal_currency_to_usd) / deposit_currency_to_usd;
    $('#converted-deposit-value').text(deposit_amount);
    $('#convert-deposit-amount').val(deposit_amount);
    $('span#modal-currency').text(withdrawal_currency);
    $('span#modal-amount').text(withdrawal_amount);
    $('span#modal-deposit-amount').text(deposit_amount);
    $('button#conversion-confirm').removeClass("disabled");
  })
});
