// This is basically underscore's debounce as a standalone version.
// Based on underscore 1.9.0
const debounce = function debounce(wait, func, immediate) {
  let timeout, result;

  const later = function later(context, args) {
    timeout = null;
    if (args) result = func.apply(context, args);
  };

  const debounced = function debounced() {
    if (timeout) clearTimeout(timeout);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    if (immediate) {
      var callNow = !timeout;
      timeout = setTimeout(later, wait);
      if (callNow) result = func.apply(this, args);
    } else {
      var _this = this;
      timeout = setTimeout(function () {
        return later.apply(null, [_this, args]);
      }, wait);
    }

    return result;
  };

  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
};

function calculateResult(decimalInput, selectedPlan) {
  // If no text is entered or no plan is selected don't bother calculating
  if ($('#principal').val() === '' || $('#plan').val() === '') return;

  const selectedPlanPercentage = parseFloat(document.querySelector(`[data-value='${selectedPlan}']`).dataset.percentage)
  const result = selectedPlanPercentage * decimalInput
  console.log(result)

  $("#profit").val(result.toFixed(2))
}

$(document).on('turbolinks:load', () => {
  $('#plan-dropdown').dropdown({
    onChange: function(value, _text, _selectedElement) {
      const decimalInput = parseFloat($("#principal").val())
      calculateResult(decimalInput, value)
    }
  })

  $('#principal').on("keyup paste blur", debounce(500, function (e) {
    const decimalInput = parseFloat(e.target.value);
    const selectedPlan = $('#plan').val()
    calculateResult(decimalInput, selectedPlan)
  }))
});
