(function (root) {
  // Based on
  // https://codepen.io/ruudt/pen/BEgKXp
  // Takes an input like "1234214" and returns "1,234,214"
  function formatThousands(value, separator) {
    if (!value) return "";

    // strip everything but integers and remove zeros from the start
    const chars = parseInt(value.replace(/[^\d]/gi, ''))
      .toString()
      .split('');
    const charsLen = chars.length

    // Add in the separator chars
    return chars.map((digit, index) => {
      if (index > 0 && (charsLen - index) % 3 === 0) {
        return `${separator}${digit}`;
      }
      return digit;
    }).join('');
  }

  // Updates the value to include commas for thousands and greater.
  // The extra logic to count selectionEnd and commas ensures the cursor stays
  // in the same position regardless of how the new value changes
  function updateValue(e) {
    const previousCursorPos = e.target.selectionEnd;
    const previousCommaCount = e.target.value.replace(/^,/, '').length;
    e.target.value = formatThousands(e.target.value, ",");
    const newCommaCount = e.target.value.replace(/^,/, '').length;
    const newCursorPosition = previousCursorPos + newCommaCount - previousCommaCount
    e.target.setSelectionRange(newCursorPosition, newCursorPosition)
  }

  // hashmaps have better lookup speed than an array,
  // though it's probably a trivial difference for 10 elements
  const allowedChars = {
    "0": true,
    "1": true,
    "2": true,
    "3": true,
    "4": true,
    "5": true,
    "6": true,
    "7": true,
    "8": true,
    "9": true
  }

  function allowOnlyDigits(e) {
    if (e.key && e.key.length === 1 && !allowedChars[e.key]) {
      e.preventDefault();
    }
  }

  /**
   * Event listener for decimal unit HTML Element.
   * Change focus to other element if either
   * 1) key is the period key
   * 2) we are at the end of the value and the key is Right Arrow
   * Otherwise, passes through event as normal so traditional tab and other meta keys work
   * @param {HTMLElement} element Receives focus if conditions are met
   */
  function handleWholeUnitKeydown(element) {
    return (e) => {
      allowOnlyDigits(e);

      if (e.key === "." ||
        (e.target.selectionEnd >= e.target.value.length && e.key === "ArrowRight")) {
        e.preventDefault();
        element.trigger('focus');
      }
    }
  }

  /**
   * Event listener for decimal unit HTML Element.
   * Change focus to other element if BOTH
   * 1) we are at the start of the value AND
   * 2) key is the Left Arrow, Backspace or Delete
   * Otherwise, passes through event as normal so traditional tab and other meta keys work
   * @param {HTMLElement} element Receives focus if conditions are met
   */
  function handleDecimalUnitKeydown(element) {
    return (e) => {
      allowOnlyDigits(e);

      if (e.target.selectionEnd === 0 &&
        (e.key === "Backspace" || e.key === "ArrowLeft" || e.key === "Delete")) {
        e.preventDefault();
        element.trigger('focus');
      }
    }
  }

  function currencyAmountInput(selector) {
    // We could use jQuery here but it really doesn't add much value
    const wholeUnit = $(`${selector} > #whole-unit > input`);
    const decimalUnit = $(`${selector} > #decimal-unit > input`);

    // skip event listeners if the elements don't exist
    if (wholeUnit && decimalUnit) {
      wholeUnit.on("keydown", handleWholeUnitKeydown(decimalUnit))
      wholeUnit.on("input", updateValue)
      wholeUnit.trigger('input')
      decimalUnit.on("keydown", handleDecimalUnitKeydown(wholeUnit))
    } else {
      console.error(
        `Could not initialize amount input because one or both required inputs do not exist:
        - '${selector} > #whole-unit > input'
        - '${selector} > #decimal-unit > input'
        - '${selector} > #currency-select'`
      )
      console.log("Don't worry, this is a non-fatal error, and only affects the UI")
    }
  }

  root.currencyAmountInput = currencyAmountInput
})(window)
