function modalApproveReject(action) {
  return (e) => {
    // Some modal content is dynamic
    const transactionCode = e.target.dataset.transactionCode
    const transactionAmount = e.target.dataset.transactionAmount
    $(`#${action}-transaction-code`).text(transactionCode)
    $(`#${action}-transaction-amount`).text(transactionAmount)
    $(`#${action}-modal`).modal({
      onApprove: function() {
        const transactionId = e.target.dataset.transactionId
        // Insert the note from the modal into the form
        // Is there a better way to do this? ¯\_(ツ)_/¯
        $(`form#${action}-${transactionId} input.transaction-note`).val($(`#${action}-modal .note`).val())
        if ($(`#${action}-modal .otp-attempt`).length){
          $(`form#${action}-${transactionId} input.transaction-otp-attempt`).val($(`#${action}-modal .otp-attempt`).val())
        }
        $(`form#${action}-${transactionId}`).trigger('submit')
      }
    })
    .modal('show')
  }
}

$(document).on('turbolinks:load', () => {
  $('.approve-button').on('click', modalApproveReject("approve"));
  $('.reject-button').on('click', modalApproveReject("reject"));
  $('.void-button').on('click', modalApproveReject("void"));
});
