// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("semantic-ui-sass");
require("channels")

// custom packs
require("packs/custom");
require("packs/tablesort");
require("packs/smart_listing.js.erb");

// Custom components
require("components/currency-amount-input");
require("components/approve-reject-modal");
require("components/user-dashboard-calculator");
require("components/approve-reject-modal-conversion");
require("components/change-state-staking-pool")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);