function modalChangeStateStakingPool(action) {
  return (e) => {
    // Some modal content is dynamic
    const stakingPoolTitle = e.target.dataset.stakingPoolTitle
    const stakingPoolNewState = e.target.dataset.stakingPoolNewState
    const new_state_capitalized = stakingPoolNewState.charAt(0).toUpperCase() + stakingPoolNewState.slice(1);
    $(`#${action}-staking-pool-title`).text(stakingPoolTitle)
    $(`#${action}-staking-pool-new-state`).text(new_state_capitalized)
    $(`#${action}-modal`).modal({
      onApprove: function() {
        const stakingPoolId = e.target.dataset.stakingPoolId
        // Insert the note from the modal into the form
        // Is there a better way to do this? ¯\_(ツ)_/¯
        
        if ($(`#${action}-modal .otp-attempt`).length){
          $(`form#${stakingPoolNewState}-${stakingPoolId} input.otp-attempt`).val($(`#${action}-modal .otp-attempt`).val())
        }
        $(`form#${stakingPoolNewState}-${stakingPoolId}`).trigger('submit')
      }
    })
    .modal('show')
  }
}

$(document).on('turbolinks:load', () => {
  $('.staking-pool-state-change-button').on('click', modalChangeStateStakingPool("state-change"));
  //$('.staking-pool-reject-button').on('click', modalApproveRejectConversion("reject"));
});